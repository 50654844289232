.ad-prod-container {
	display: flex;
	flex-wrap: wrap;
}

.ad-prod-card {
	display: flex;
	flex: 1;
	background-color: white;
	//min-width: 200px;
	margin: 0.05em;
	border-radius: 4px;
	box-shadow: 0 0 25px rgba(black, 0.1);
	flex-wrap: wrap;
	flex-direction: row;
	min-width: 100%;
	justify-content: space-between;
	align-items: center;
	p {
		margin: 0 1em;
		padding: 0;
	}
}

.ad-card-item {
	display: flex;
	flex: 1;
	justify-content: flex-start;
	margin: 1em;
	//min-width: 100px;

	p {
		margin: 0;
		padding: 0;
		//word-break: break-all;
	}
	@media only screen and (max-width: $medium-screen) {
		min-width: 100px;
		P {
			word-break: break-all;
			font-size: 0.8em;
		}
	}
}

.ad-card-button {
	@include btn-text-unselect;
	display: inline-flex;
	flex: 0;
	justify-content: flex-start;
	align-items: center;
	margin: 1em;
	padding: 8px 20px;
	color: dodgerblue;
	cursor: pointer;
	border-radius: 4px;
	background-color: #f8f8f8;
	&:hover {
		background-color: #f0f0f0;
		//text-decoration-line: underline;
	}
	p {
		margin: 0;
		padding: 0;
	}
}

.admin-sort {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0.5em;
	//align-items: center;
	h3 {
		@include n-m-p;
	}
}
