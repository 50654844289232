.home-hero {
	margin-top: $hero-padding-top;
	min-height: 90vh;
	background-image: url('../../../public/bg2.jpeg');
	background-color: #313234;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 10% 8%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	color: $color-light;
	text-shadow: 0px 0px 10px rgba(black, 0.2);
	flex: 1;
	color: white;
	letter-spacing: 2px;
	text-transform: uppercase;
	border-bottom: 5px solid black;

	@mixin hero-text-pad {
		padding: 0 5px 0 10px;
		background-color: rgba($color-pink, 0.85);
		color: black;

		//	background-color: black;
		//	color: $color-pink;
	}

	h1 {
		font-size: 3.5em;
		font-weight: 500;
		margin: 0.2em 0;
		letter-spacing: 10px;
		@include hero-text-pad;
		border-top: 5px solid black;
		border-bottom: 5px solid black;
		//white-space: nowrap;

		@media only screen and (max-width: 450px) {
			//padding-right: 0;
			letter-spacing: 5px;
			white-space: nowrap;
		}
		@media only screen and (max-width: 450px) {
			//padding-right: 0;
			letter-spacing: 5px;
			white-space: pre-wrap;
		}
	}

	h2 {
		font-size: 1.8em;
		font-weight: 300;
		letter-spacing: 5px;
		margin: 0;

		//border-top: 5px solid black;
		//border-bottom: 5px solid black;

		@include hero-text-pad;
	}

	.htc {
		//background-color: red;
		//margin: 2em;
	}

	@media only screen and(max-width: $medium-screen) {
		//background-size: 120% 60vh;
		min-height: 60vh;
		h1 {
			font-size: 1.5em;
		}

		h2 {
			font-size: 1.2em;

			letter-spacing: 2px;
		}

		h3 {
			font-size: 0.5em;
		}
		padding: 2% 8%;
	}

	@media only screen and(max-width: $small-screen) {
		min-height: 50vh;
		//background-size: 180% 50vh;
	}

	.hero-action-button {
		@include btn-general;
		background-color: black;
		margin: 2em 0;
		border-radius: 0px;
		transition: 0.3s;
		min-height: 50px;
		min-width: 180px;
		align-items: center;
		justify-content: center;
		//border: 2px solid black;

		p {
			font-size: 1em;
			//text-transform: capitalize;
			letter-spacing: 2px;
			//color: $color-pink;
		}

		@media only screen and(min-width:$large-screen) {
			&:hover {
				background-color: whitesmoke;
				color: black;
			}
		}

		@media only screen and (max-width: $large-screen) {
			transition: 0.2s;
			&:active {
				background-color: #d5d5d5;
			}
		}
	}
}
