$nav-item-padding: 0.4em 1em;
@import './navdropdown';

@mixin nav-item-hover {
	letter-spacing: 2px;
	font-size: 0.9em;
	@include n-m-p;

	border-bottom: 1px solid transparent;

	&:hover {
		border-bottom: 1px solid $color-pink;
	}
}

.v-navbar {
	background-color: black;
	align-items: center;
	height: 10vh;
	min-height: 4em;
	display: flex;
	color: whitesmoke;
	z-index: 19;

	@media only screen and (max-width: $medium-screen) {
		height: 4em;
		min-height: 4em;
	}
}

@import './navbarlogo';

.v-nav {
	display: flex;
	flex: 4;
	justify-content: space-between;
	color: whitesmoke;

	@media only screen and (max-width: $large-screen) {
		padding: 1em 0;
	}
}

.v-navitem {
	@media only screen and(min-width:$large-screen) {
		@include nav-item-hover;
	}
	display: flex;
	align-items: center;
	cursor: pointer;

	@media only screen and(max-width:$medium-screen) {
		margin: 0.5em 0;
	}
}

.nav-dd {
	background-color: $color-black;
	padding: 2px 0;
	@media only screen and(min-width:$large-screen) {
		@include nav-item-hover;
	}
	@media only screen and(max-width:$medium-screen) {
		margin: 0.5em 0;
	}
}

.nav-dd-item {
	padding: 10px 20px;
}

.v-nav-collapse {
	padding: 0% 4.5%;
	background-color: black;
}

.v-nav-icon-container {
	display: flex;
	cursor: pointer;
}

.cart-badge {
	border-radius: 500px;
	align-items: center;
	justify-content: center;
	display: flex;
	height: 15px;
	margin-left: -12px;
	margin-right: 0px;
	background-color: $color-pink;
	margin-top: -10px;
	padding: 0 3px;

	span {
		font-size: 10px;
		font-weight: 800;
		border-radius: 500px;
		color: $color-black;
		margin-left: 2px;
	}
}

.custom-toggler {
	color: red;
	border: 1px solid red;
}

.nav-toggle-v {
	background-color: red;
	border: 1px solid red;
	border: none;
	&:active:after {
		border: none;
	}
}
