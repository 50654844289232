//@include

.explore-page {
	display: flex;
	margin: 15vh 7% 0 7%;
	align-items: center;
	padding: 0;
	text-transform: uppercase;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	box-sizing: content-box;

	@media only screen and (max-width: $medium-screen) {
		justify-content: center;
		text-align: center;
	}

	h3 {
		@include n-m-p;
		font-weight: 400;
		color: black;
		text-align: inherit;
	}

	h5 {
		@include n-m-p;
		font-weight: 300;
		margin-bottom: 1em;
		text-align: inherit;
	}
}

.custom-select {
	width: 300px;
	height: 50px;
	border-radius: 0;
	padding: 0 20px;
	cursor: pointer;
	letter-spacing: 2px;
	display: flex;
	background-color: white;

	&:focus {
		outline: 2px solid black;
		background-color: white;
	}
}

.all-products-explore {
	display: flex;
	flex-wrap: wrap;
	margin: 0 4%;
	justify-content: center;
	padding: 0;
	flex-shrink: 1;
}

.explore-card {
	min-width: 300px;
	align-self: flex-start;
	float: bottom;
}

.load-more-button {
	display: inline-flex;
	background-color: $color-black;
	transition: 0.2s;
	cursor: pointer;
	margin: 1em 4% 10vh 5%;
	//border: 2px solid black;
	color: whitesmoke;
	align-items: center;
	justify-content: center;
	@include btn-text-unselect;

	@media only screen and(min-width:$large-screen) {
		&:hover {
			background-color: #d5d5d5;
			color: black;
		}
	}

	@media only screen and (max-width: $large-screen) {
		&:active {
			background-color: #d5d5d5;
			opacity: 0.5;
		}
	}

	p {
		padding: 0;
		margin: 0;
		font-size: 16px;
		padding: 1em 2em;
		color: $color-light;
		font-weight: 500;
		color: inherit;
	}
}
