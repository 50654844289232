//@include

$profile-page-box-padding: 1.5em;

@mixin profile-page-general {
	background-color: white;
	padding: $profile-page-box-padding;
	box-shadow: $box-shadow-one;
	display: flex;
	flex-direction: column;
	margin: 1em 0;
}

.profile-page {
	margin: 15vh $hp-big;
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	flex-direction: column;
	letter-spacing: 2px;
	box-sizing: border-box;

	h3 {
		@include n-m-p;
	}
}

@mixin admin-button {
	@include btn-text-unselect;
	display: inline-flex;
	//padding: 10px 20px;
	background-color: whitesmoke;
	width: 200px;
	height: 40px;
	cursor: pointer;
	border-radius: 4px;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: rgba(black, 0.2);
	}

	p {
		color: dodgerblue;
		@include n-m-p;
		text-align: center;
	}
}

.admin-panel-button {
	@include admin-button();
}

.admin-panel-button-alt {
	@include admin-button();

	background-color: black;
	P {
		color: whitesmoke;
	}
	&:hover {
		background-color: #d5d5d5;
		p {
			color: black;
		}
	}
}

.my-profile {
	@include profile-page-general;

	h3 {
		margin-bottom: 1em;
	}
}

.my-orders {
	margin: 0 0;
	display: flex;
	flex-direction: column;
}

.order-table {
	width: 100%;
	flex-direction: column;
	overflow: scroll;

	th,
	td {
		padding: 10px 2em;
	}
}

.order-card {
	@include profile-page-general;
	flex-direction: row;
	justify-content: space-between;
	margin: 1em 0;
	flex-wrap: wrap;

	p {
		@include n-m-p;
		min-width: 200px;
		margin: 0.5em;
	}
}
