@import './stylecomponents/page';
@import './stylecomponents/constants';
@import './stylecomponents/mixins';
@import './stylecomponents//primary/nav';
@import './stylecomponents/home/hero';
@import './stylecomponents/home/sections';
@import './stylecomponents//home/consult';
@import './stylecomponents/primary/footer';
@import './stylecomponents/product/productpage';
@import './stylecomponents/login/loginpage';
@import './stylecomponents/cart/cartpage';
@import './stylecomponents//order/orderdetails';
@import './stylecomponents/cat/categorypage';
@import './stylecomponents/admin/adminpage';
@import './stylecomponents/profile/profilepage';
@import './stylecomponents/primary/navsearch';
@import './stylecomponents/primary/searchprod';
@import './stylecomponents/primary/footericons';
@import './stylecomponents/cat/explorepage';
@import './stylecomponents/about/aboutpage';
@import './stylecomponents/admin/adminav';
@import './stylecomponents/primary/adminsearch';
@import './stylecomponents/admin/addproduct';
@import './stylecomponents/home/storysection';
@import './stylecomponents/product/review';
@import './stylecomponents/profile/editprofile';
@import './stylecomponents/enhancements/enhancements';
@import './stylecomponents/terms/terms';

html,
* {
	box-sizing: border-box;
}
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	::selection {
		color: black;
		background: rgba($color-pink, 0.5);
	}
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.display-card-image {
	height: 300px;
	width: 300px;
}

.page-section-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 2em;
	min-width: 300px;
	box-sizing: border-box;
	transition: 0.5s;
	cursor: pointer;
	padding-bottom: 1em;
	background-color: white;

	@media only screen and(max-width: $medium-screen) {
		min-width: 200px;
		margin: 1em;
	}

	&:hover {
		background-color: white;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
	}

	h6 {
		font-size: 1.2em;
		margin: 1em 0 0 0;
		font-weight: 500;
	}

	p {
		margin: 0;
		padding: 0;
		font-weight: 300;
	}
}

.v-cart-icon {
	margin-bottom: 1px;
	height: 16px;
	margin-right: 10px;
}

.page-vertical-padding {
	padding: 15vh 0;
}

.v-image {
	object-fit: cover;
	background-color: #313234;
}

.v-contain-image {
	object-fit: contain;
	background-color: #f9f9f9;
}

.opacity-5 {
	opacity: 0.5;
}

.shr-icon {
	//smargin-right: 8px;
	padding: 5px;
	border-radius: 4px;
	&:hover {
		color: black;
		background-color: whitesmoke;
	}
}

.agree-signup-terms {
	display: flex;
	width: 25em;

	@media only screen and (max-width: $medium-screen) {
		width: 15em;
	}

	p {
		margin: 0;
		padding: 0;
		font-size: 0.7em;
	}
	span {
		cursor: pointer;
		color: $color-pink;

		&:hover {
			text-decoration-line: underline;
		}
	}
}

.thinkcrypt {
	background-color: black;
	padding: 0.5em 4%;
	display: flex;
	justify-content: flex-end;
	letter-spacing: 2px;
	p {
		color: whitesmoke;
		font-size: 0.8em;
		font-weight: 500;
		padding: 0;
		margin: 0;
	}
	a {
		color: $color-pink;
		text-decoration-line: none;
		font-weight: 700;
		&:hover {
			color: $color-pink;
			text-decoration-line: underline;
		}
	}
}

.reset-password {
	padding: 15vh 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-wrap: wrap;
	background-color: white;
	flex-direction: column;
}

.edit-reset-input {
	margin: 1em 0;
	//background-color: rgba(crimson, 0.1);
	display: flex;
	flex-direction: column;

	label {
		margin-right: 1em;
	}

	input {
		width: 20%;
		min-width: 600px;
		margin-bottom: 0;
		height: 50px;
		padding: 10px 20px;
		background-color: #f1f1f1;
		border: none;
		letter-spacing: 2px;
		&:focus {
			outline: 2px solid black;
		}
		@media screen and (max-width: $medium-screen) {
			min-width: 500px;
		}

		@media screen and (max-width: $small-screen) {
			min-width: 450px;
		}

		@media screen and (max-width: $x-small-screen) {
			min-width: 300px;
		}
		@media screen and (max-width: 320px) {
			min-width: 250px;
		}
	}
}

.ppp {
	min-width: 600px;
	margin-bottom: 1em;
	@media screen and (max-width: $medium-screen) {
		min-width: 500px;
	}

	@media screen and (max-width: $small-screen) {
		min-width: 450px;
	}

	@media screen and (max-width: $x-small-screen) {
		min-width: 300px;
	}
	@media screen and (max-width: 320px) {
		min-width: 250px;
	}
}

.custom-text {
	font-size: 1.1em;
	margin: 0;
	padding: 0;
	@media only screen and(max-width:$medium-screen) {
		p {
			font-size: 0.9em;
		}
	}
}

.text-link-wrapper {
	color: black;
	text-decoration-line: none;

	&:hover {
		text-decoration-line: underline;
		color: black;
	}
}
