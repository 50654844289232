//@at-root

@mixin general-button-data {
	@include btn-text-unselect;

	display: inline-flex;
	background-color: $color-black;
	cursor: pointer;
	transition: 0.2s;
	margin-right: 25px;
	align-items: center;
	justify-content: center;
	height: 45px;
	width: 8em;
	margin-bottom: 0.8em;
	color: white;
	box-sizing: border-box;

	@media only screen and(min-width:$large-screen) {
		&:hover {
			background-color: #d5d5d5;
			color: black;
		}
	}

	@media only screen and (max-width: $large-screen) {
		transition: 0.2s;
		&:active {
			background-color: #a5a5a5;
		}
	}

	p {
		margin: 0;
		padding: 0;
		color: inherit;
		font-size: 17px;
		font-weight: 400;
	}
}

.login-button {
	@include general-button-data();
}

.long-button {
	@include general-button-data();

	width: 10em;
	margin: 0;
}

.text-button {
	@include btn-text-unselect;

	display: flex;
	//width: 10em;
	margin: 0.5em 0;
	color: dodgerblue;
	cursor: pointer;
	&:hover {
		text-decoration-line: underline;
	}
	p {
		margin: 0;
		padding: 0;
	}
}

.black-button {
	@include general-button-data();
}

.cancel-button {
	@include general-button-data();
	background-color: crimson;
	//border: 2px solid crimson;
	&:hover {
		background-color: rgba(crimson, 0.1);
		color: crimson;
	}
}
