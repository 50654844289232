//@include

.category-page {
	display: flex;
	margin: 15vh 4% 0 4%;
	flex-direction: column;
	align-items: center;
	padding: 0;

	h1 {
		text-transform: uppercase;
		margin: 1px 0;
		padding: 0;
		text-align: center;
		font-weight: 400;
		color: black;
	}

	h4 {
		margin-top: 5px;
		padding: 0;
		text-align: center;
		font-weight: 300;
		color: $color-pink;
	}
}

.all-products {
	display: flex;
	flex-wrap: wrap;
	margin: 1% 4% 4% 4%;
	align-items: center;
	justify-content: space-evenly;
	padding: 0;
}
