//@at-root

.a-nav-search-bar {
	display: flex;
	flex: 5;
	cursor: text;
	margin: 0 2%;
	//background-color: yellow;
	justify-content: flex-start;
}

.a-search-nav {
	display: flex;
	min-width: 200px;
	padding: 10px 20px;
	background-color: rgba(white, 0.07);
	padding: 8px 10px;
	align-items: center;
	justify-content: space-between;
	color: rgba(whitesmoke, 0.4);
	border-radius: 2px;
	letter-spacing: 2px;

	&:hover {
		background-color: rgba(white, 1);
		color: black;
	}

	span {
		color: inherit;
		font-size: 15px;
		margin: 0 10px;
	}
}

.a-search-icon {
	height: 15px;
	color: inherit;
	margin: 0 10px;
}

.a-search-panel {
	display: flex;
	position: fixed;
	background-color: white;
	width: 100%;
	height: 100vh;
	transition: visibility 0.05s linear;
	padding: 10vh 5% 0 5%;
	align-items: center;
	//justify-content: center;
	flex-direction: column;
	box-shadow: 0 0 20px rgba(black, 0.1);
	z-index: 11;

	.sc-close-icon-c {
		display: flex;
		flex: 0;
		padding: 20px 25px;
		border-radius: 0;
		cursor: pointer;
		align-self: flex-end;
		margin-bottom: 40px;

		&:hover {
			background-color: black;
			color: white;
		}
	}

	.sc-close-icon {
		height: 30px;

		color: inherit;
	}

	@media only screen and (max-width: $medium-screen) {
		padding: 10vh 1% 0 1%;
		margin-bottom: 25px;
		.sc-close-icon-c {
			padding: 10px;
		}
		.sc-close-icon {
			height: 20px;
		}
	}
}

.a-sb-input-container {
	width: 60%;
	flex: 0;
	align-items: center;
	justify-content: center;
}

.a-search-bar-input {
	display: flex;
	flex: 1;

	min-width: 100px;
	height: 50px;
	border: none;
	//background-color: whitesmoke;
	//border-radius: 4px;
	padding: 0 15px;
	border: 1px solid black;
	letter-spacing: 2px;
	&:focus {
		border: 3px solid black;
		outline: none;
	}
	@media only screen and (max-width: $medium-screen) {
		min-width: 200px;
		font-size: 0.8em;
	}
}

.a-search-select {
	display: flex;

	height: 50px;
	min-width: 120px;
	border: 1px solid black;
	letter-spacing: 2px;
	&:focus {
		border: 3px solid black;
		outline: none;
	}
	@media only screen and(max-width:$medium-screen) {
		min-width: 100px;
		font-size: 0.7em;
	}
}

.a-search-bar-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	min-width: 50px;
	background-color: black;
	border: 1px solid black;
	border-left-width: 0;
	color: white;
	cursor: pointer;
	&:hover {
		background-color: whitesmoke;
		color: black;
	}
}

.search-icon-dd {
	color: inherit;
	height: 20px;
}

.panel-visible {
	visibility: visible;
}
.panel-hidden {
	visibility: hidden;
}
