.v-pc-icon-contaner {
	display: flex;
	flex: 1;
	padding: 0 5px;
	justify-content: space-between;
	width: 100%;
}

.card-banner {
	@media only screen and (min-width: $medium-screen) {
		transition: ease-in 0.5s;
	}
	overflow: hidden;
	background-color: black;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	margin: 0 0.5em;
	border-radius: 100px;
	padding-left: 1em;
	cursor: pointer;
}

.v-pc-icons {
	height: 20px;
	width: 20px;
	margin: 0.8em 10px;
	//padding: 10px;
	color: black;
	cursor: pointer;

	&:hover {
		border-radius: 500px;
		//background-color: whitesmoke;
	}
}

.favd {
	height: 20px;
	width: 20px;

	margin: 0.8em 10px;
	//padding: 5px;
	color: black;
	cursor: pointer;

	&:hover {
		border-radius: 500px;
		//background-color: whitesmoke;
	}
	color: $color-pink;
}

.ffavd {
	color: $color-pink;
}
