@import './section-arrows';
@import './section-card';
@import './viewmore';

.home-sections {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 5%;

	.home-section-headers {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 5%;
		color: $color-black;
		letter-spacing: 2px;
		text-align: center;

		h3 {
			font-size: 2.5em;
			font-weight: 500;
			@include n-m-p;

			@media only screen and(max-width: $medium-screen) {
				font-size: 2em;
			}
		}

		h4 {
			font-weight: 300;
			color: rgba(black, 0.7);
		}
	}
}

@mixin section {
	width: 100%;
	display: flex;
	align-items: stretch;
	white-space: nowrap;
	box-sizing: border-box;
	padding: 10px 4%;
}

.section-container {
	@include section;
	justify-content: flex-start;
	margin: $padding-horizontal 0;
	overflow: scroll;
	scroll-behavior: smooth;
	margin: 0 -9%;
	box-sizing: border-box;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;

	@media only screen and(max-width: $medium-screen) {
		margin: 0 -12%;
	}
}

.single-section {
	@include section;
	justify-content: center;
	overflow: hidden;

	@media only screen and(max-width: $medium-screen) {
		justify-content: flex-start;
	}
}

.gift-section {
	@include section;
	justify-content: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.gift-card-text {
	text-align: center;
	padding: 1em;
}

@import './giftsection';
