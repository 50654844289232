//@include

.edit-profile {
	display: flex;
	min-height: 100vh;
	padding: 15vh 10%;
	flex-direction: column;
	background-color: white;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.edit-pro-input-div {
	display: flex;
	flex-direction: column;
	width: 60%;
	align-self: center;
	justify-self: center;
	margin-bottom: 10px;
	padding: 0 4%;
	@include remove-input-arrow;

	input {
		width: 100%;
		min-width: 300px;
		margin-bottom: 1em;
		height: 50px;
		padding: 10px 20px;
		background-color: #f1f1f1;
		border: none;
		letter-spacing: 2px;
		&:focus {
			outline: 2px solid black;
		}
	}

	@media only screen and(max-width:$medium-screen) {
		width: 100%;
		input {
			width: 100%;
			min-width: 300px;
		}
	}
}
