//screen sizes
$small: 600px;
$medium: 768px;
$large: 992x;
$extra-large: 1200px;

//screen breakpoints
$x-small-screen: 500px;
$small-screen: 600px;
$medium-screen: 768px;
$large-screen: 996px;
$x-large-screen: 1200px;

//colors
$color-primary: #000;
$primary-hover: rgba(white, 0.2);
$color-background: #fff;
$color-light: #fff;
$white-text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
$color-blac: #252628;
$color-black: black;
$button-hover: rgba(black, 0.5);
$black-color: #393131;
$color-pink: #fb3290;

//margins and padding
$padding-horizontal: 5%;
$padding-horizontal-half: $padding-horizontal/2;
$card-width: 300px;
$hp: 5%;

$hp-big: 5%;

//
$box-shadow-one: 0 0 25px rgba(black, 0.1);

$navbar-height: 4em;

$hero-padding-top: $navbar-height;

$section-border-radius: 10px;
