.view-more-card {
	margin: 1em 0.5em;
	min-width: 380px;
	background-color: rgba(black, 1);
	display: flex;
	border-radius: $section-border-radius;
	//box-shadow: 0 0 25px rgba(black, 0.1);
	box-shadow: 4px 4px 10px rgba(black, 0.1);

	background-size: cover;
	background-position: center;
	box-sizing: border-box;
	overflow: hidden;

	@media only screen and(max-width: $medium-screen) {
		min-width: 300px;
	}

	&:hover {
		//background-color: whitesmoke;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
	}
}

.view-more-inner {
	cursor: pointer;

	display: flex;
	flex: 1;
	background-color: rgba(black, 0.7);
	backdrop-filter: blur(2px);
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	box-sizing: border-box;

	p {
		color: whitesmoke;
		font-size: 1.5em;
		font-weight: 50;
		text-transform: uppercase;
		letter-spacing: 5px;
		margin: 0;
		text-align: center;
	}
	&:hover {
		//	background-color: black;
		background-color: rgba(black, 0.9);

		backdrop-filter: blur(10px);
		p {
			font-size: 1.6em;
		}
	}
}
