.product-page {
	.product-card-top {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-sizing: border-box;
	}
}

.page-product {
	max-width: 100vw;
	display: flex;
	padding: 12vh 0 0 0;
	flex: 1;
	color: $black-color;
	flex-wrap: wrap;
	justify-content: space-between;
	box-sizing: border-box;
	overflow: hidden;
	background-color: white;
	box-sizing: border-box;

	@media only screen and(max-width:$large-screen) {
		flex-direction: column;
	}

	//product details section beside image
	.product-details {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 1em $hp 1em $hp;
		box-sizing: border-box;
		color: black;
		box-sizing: border-box;

		//product name
		h1 {
			@include n-m-p;
			font-size: 2em;
			margin-bottom: 16px;
			font-weight: 500;
			@media only screen and (max-width: $large-screen) {
				font-size: 1.8em;
			}
		}

		h2 {
			@include n-m-p;
			margin: 1em 0;
			font-size: 2em;
			font-weight: 500;
		}

		//price
		h4 {
			@include n-m-p;
			margin-bottom: 16px;
			font-size: 1.1em;
			color: rgba(black, 0.7);
		}

		//product price
		.price-text {
			font-size: 1.5em;
			font-weight: 600;
			color: black;
			@media only screen and (max-width: $large-screen) {
				font-size: 1.3em;
			}
		}

		//notes
		h5 {
			@include n-m-p;
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: 300;
		}

		//category sub category stock
		p {
			@include n-m-p;
			//font-size: 1.1em;
			font-weight: 500;
			margin: 16px 0;
			line-height: 1.6;
		}

		h3 {
			@include n-m-p;
		}
	}
}

@import './productimage';
@import './prodbuttons';
