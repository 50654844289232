.about-page {
	margin: 15vh 5%;
	letter-spacing: 2px;

	h3 {
		font-size: 2em;
		margin-bottom: 1.5em;
	}

	p {
		font-size: 1.1em;
		line-height: 1.5em;
		margin-bottom: 1.2em;
	}
	@media only screen and(max-width:$medium-screen) {
		h3 {
			font-size: 1.5em;
		}
		p {
			font-size: 0.9em;
		}
	}
}

.about-team {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 4em;
	flex-wrap: wrap;

	h6 {
		font-size: 1em;
		text-align: center;
	}
	p {
		font-size: 0.8em;
		font-weight: 700;
		text-align: center;
		@include n-m-p;
	}
}

.team-container {
	margin: 0 2em;
	//background-color: red;
}

.team-image {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2em 0;
}

.team-img {
	border-radius: 500px;
	align-self: center;
	object-fit: cover;
}

.team-icons-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.5em 0;
}

.team-icons {
	margin: 0 0.3em;
	cursor: pointer;
	color: black;
	&:hover {
		color: $color-pink;
	}
}
