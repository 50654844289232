@mixin edit-inputs {
	display: flex;
	border-radius: 0;
	border: none;
	background-color: white;
	margin-bottom: 1em;
	padding: 0 10px;
	box-shadow: 0 0 20px rgba(black, 0.2);
	border: 1px solid rgba(black, 0.3);

	&:focus {
		outline: 2px solid black;
		border: none;
	}
}

.admin-product-edit {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 1em;
	box-sizing: border-box;

	@media only screen and(max-width:$medium-screen) {
		max-width: 230px;
		label {
			margin-top: 1em;
		}
	}

	input {
		@include edit-inputs();
		height: 50px;
	}
	textarea {
		@include edit-inputs();
		padding: 10px;
	}
}
