//@include

.footer-full {
	background-color: black;
}

.footer-c {
	background-color: inherit;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 4%;
	p {
		@include n-m-p;
		font-size: 0.8em;
		letter-spacing: 3px;
	}
	a {
		color: $color-pink;
		text-decoration-line: none;
		margin: 0 5px;
		//font-weight: 700;
		&:hover {
			color: $color-pink;
			text-decoration-line: underline;
		}
	}
}

.footer {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	color: black;
	flex-wrap: wrap;
	background-color: whitesmoke;
	padding: 80px $hp;

	.footer-address-section {
		display: flex;
		flex: 1;
		flex-direction: column;
		letter-spacing: 2px;
		min-width: 300px;
		margin-bottom: 2em;
		margin-right: 2em;
		//background-color: dodgerblue;

		h3 {
			margin: 0.5em 0;
			padding: 0;
			margin-bottom: 1em;
		}

		p {
			font-size: 0.9em;
			margin: 0.5em 0;
			padding: 0;
		}
		a {
			margin: 0.5em 0;

			font-size: 0.9em;
		}
	}

	.footer-link-section {
		//background-color: red;
		align-items: flex-start;
		justify-content: space-between;
		display: flex;
		flex: 2;
		flex-wrap: wrap;
		letter-spacing: 2px;
		.footer-sections {
			display: flex;
			flex-direction: column;
			margin-bottom: 2em;
			min-width: 200px;

			a {
				font-size: 0.8em;
				margin: 0.5em 0;
				cursor: pointer;
				color: black;
				text-decoration-line: none;
				&:hover {
					text-decoration-line: underline;
					color: $color-pink;
				}
			}
			h5 {
				font-size: 1.1em;
				margin-bottom: 1em;
			}

			@media only screen and (max-width: $medium-screen) {
				h5 {
					font-size: 0.9em;
				}
				a {
					font-size: 0.7em;
				}
			}
		}
	}
}

.v-footer-icon {
	color: black;
	height: 1em;
	margin-right: 1em;
}

.footer-mail {
	color: black;
	cursor: pointer;
	text-decoration-line: none;

	&:hover {
		text-decoration-line: underline;
		color: $color-pink;
	}
}
