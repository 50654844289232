@import './loginbutton';

%login-inputs {
	width: 25em;
	padding: 0 0.8em;
	margin: 0.2em 0 1.5em 0;
	border: none;
	background-color: white;
	box-shadow: 0 0 10px rgba(black, 0.2);
	letter-spacing: 2px;

	&::placeholder {
		letter-spacing: 2px;
		text-transform: capitalize;
	}

	&:focus {
		outline: 2px solid black;
	}

	@media only screen and (max-width: $medium-screen) {
		width: 15em;
	}
}

.login-page {
	margin: 15vh 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;

	.login-form {
		flex-direction: column;
		justify-content: center;
		flex: 1;
		display: flex;
		color: $color-black;
		@include remove-input-arrow;

		h3 {
			margin: 1em 0;
			padding: 0;
			text-transform: uppercase;
		}

		input {
			@extend %login-inputs;
			height: 45px;
		}

		textarea {
			@extend %login-inputs;
			padding: 0.5em 0.8em;
		}

		a {
			color: dodgerblue;
			text-decoration-line: underline;
		}
	}
}

.forgot-password {
	margin-top: -1em;
	margin-bottom: 1em;
	font-size: 0.8em;
	cursor: pointer;
	&:hover {
		text-decoration-line: underline;
	}
}
