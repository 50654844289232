.card-image-section {
	//background-color: black;
	//border-radius: 30px;
}

.section-card {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	margin: 1em 0.5em;
	min-width: 380px;
	box-sizing: border-box;
	padding: 0 0 10px 0;
	background-color: white;
	box-shadow: 4px 4px 10px rgba(black, 0.05);
	max-width: 300px;
	overflow: hidden;
	border-radius: $section-border-radius;
	align-self: center;
	justify-self: center;
	transition: 0.1s;

	@media only screen and (min-width: $large-screen) {
		&:hover {
			box-shadow: 0px 0px 20px rgba(black, 0.2);
			transform: scale(1.01);
			z-index: 8;
		}
	}

	@media only screen and(max-width: $medium-screen) {
		min-width: 300px;
	}

	.section-card-text {
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding: 5px 15px;
		min-width: 200px;
		overflow: hidden;
		flex: 1;

		h6 {
			display: flex;
			margin: 1px 0;
			font-weight: 700;
			padding: 0;
			flex-grow: 1;
			font-size: 1.1em;
			flex: 1;
			text-overflow: ellipsis;
			@media only screen and (max-width: $medium-screen) {
				font-size: 0.9em;
			}
		}

		p {
			margin: 0;
			padding: 0;
			font-weight: 300;
		}

		.card-price {
			@include n-m-p;
			font-weight: 500;
			margin: 0.75em 0 0 0;
		}

		.section-sub-category {
			margin: 2px 0;
			font-size: 0.75em;
			font-weight: 500;
			text-decoration-color: $color-pink;
			color: $color-pink;
			text-transform: uppercase;
			@media only screen and (max-width: $medium-screen) {
				font-size: 0.7em;
			}
		}

		.section-size-text {
			margin: 1px 0;
			color: rgba(0, 0, 0, 0.5);
			font-size: 0.9em;
			font-weight: 500;
		}
	}
}

@import './section-icons';
