.section-arrows {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.arrow-icon {
		height: 16px;
		color: $black-color;

		@media only screen and(max-width: $medium-screen) {
			height: 15px;
		}
	}

	.arrows {
		height: 40px;
		width: 40px;
		border-radius: 500px;
		transition: 0.2s;
		background-color: white;

		backdrop-filter: blur(5px);
		z-index: 9;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		@include btn-text-unselect;

		&:hover {
			background-color: rgba(white, 1);
			transform: scale(2);
		}

		@media only screen and(max-width: $medium-screen) {
			//padding: 3.5% 4.2%;
			&:hover {
				transform: none;
			}
		}
	}
}
