//

.page {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	//background-image: url('../../public/bg.jpg');
	background-color: #f9f9f9;
	letter-spacing: 2px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 0;
}

.container-center {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	//color: black;
	margin: 0;
	padding: 0;
}

div {
	box-sizing: border-box;
}
