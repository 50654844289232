//@include

@mixin footer-icn($height) {
	@include btn-text-unselect;
	color: black;
	height: $height;
	cursor: pointer;
	margin-left: 15px;
	border-radius: 2px;

	&:nth-of-type(1) {
		margin-left: 0;
	}

	&:hover {
		color: $color-pink;
	}
}

.footer-section-icons {
	display: flex;
	flex-direction: column;
	margin-bottom: 2em;
	min-width: 200px;
	h5 {
		font-size: 1.1em;
		margin-bottom: 1em;
	}

	@media only screen and (max-width: $medium-screen) {
		h5 {
			font-size: 0.9em;
		}
		a {
			font-size: 0.7em;
		}
	}
	.footer-icons-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.footer-icons {
			@include footer-icn(30px);
		}
	}
}

.back-to-top-icon {
	@include footer-icn(40px);
}
