.nav-brand-logo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	margin-left: 4.5%;
	cursor: pointer;
	@media only screen and(max-width:360px) {
		padding: 3px 0;
	}

	@media only screen and(max-width:320px) {
		padding: 5px 0;
	}
}

.nav-brand-item {
	display: flex;
	flex: 1;
	color: $color-light;
	font-size: 2em;
	font-weight: 300;
	cursor: pointer;
	margin: 0;

	h1 {
		margin: 0;
		padding: 0;
	}
}
