.order-details-page {
	display: flex;
	//background-color: red;
	//padding: 15vh 0%;
	//align-items: center;
	flex-wrap: wrap-reverse;
	letter-spacing: 2px;

	@media only screen and(max-width:$medium-screen) {
		flex-direction: column-reverse;
	}

	h3 {
		margin: 10px 0 15px 0;
	}
}

$order-page-shadow: $box-shadow-one;

.order-details {
	display: flex;
	flex: 1;
	flex-direction: column;
	//min-height: 70vh;
	justify-content: space-evenly;
	margin: 0 4%;
	//background-color: yellowgreen;

	.order-item-container {
		background-color: white;
		margin-bottom: 30px;
		box-shadow: $order-page-shadow;
		padding: 25px;
		border-radius: 4px;
		box-sizing: border-box;

		p {
			@include n-m-p;
			margin-bottom: 1em;
		}
		@media only screen and(max-width:$medium-screen) {
			p {
				font-size: 0.8em;
			}
		}
	}
}

.order-details-item {
	display: flex;
	flex: 1;
	justify-content: space-between;
	padding: 10px 0;
	align-items: center;
	//background-color: whitesmoke;
	margin-bottom: 10px;
	border-radius: 4px;
	box-sizing: border-box;

	p {
		@include n-m-p;
	}
	@media only screen and(max-width:$medium-screen) {
		p {
			font-size: 0.8em;
		}
	}
}

.order-summary {
	display: flex;
	flex-direction: column;
	//flex: 1;
	padding: 25px;
	box-shadow: $order-page-shadow;
	border-radius: 4px;
	background-color: white;
	min-width: 200px;
	//align-items: center;
	margin: 0 4%;
	//margin-bottom: 5vh;
	box-sizing: border-box;
}

.order-select {
	//min-width: 180px;
	height: 50px;
	border-radius: 0;
	padding: 0 10px;
	cursor: pointer;
	letter-spacing: 2px;
	display: flex;

	&:focus {
		outline: 2px solid black;
		background-color: white;
	}
}

@mixin badge {
	padding: 5px 10px;
	align-items: center;
	justify-content: center;
	margin: 10px 0;

	p {
		margin: 0;
		padding: 0;
		font-weight: 600;
	}
}

.positive-badge {
	@include badge;
	background-color: rgba(teal, 0.05);
}

.negative-badge {
	@include badge;
	color: crimson;
	background-color: rgba(crimson, 0.05);
}
