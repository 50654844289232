//@include

.prod-page-button-container {
	display: flex;

	.prod-page-button {
		@include btn-general;
		border-radius: none;
		background-color: black;
		transition: 0.2s;
		align-items: center;
		justify-content: center;
		border-radius: 0;

		@include btn-hover;

		p {
			@include n-m-p;
			color: white;
			font-size: 0.9em;
			font-weight: 500;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}
}

.prod-qty-button {
	@include btn-general;
	cursor: default;

	.v-plus-minus {
		@include btn-text-unselect;
		flex: 1;
		background-color: black;
		cursor: pointer;
		color: white;
		@include v-flex-center;
		@include btn-hover;
	}

	p {
		@include n-m-p;
		font-size: 18px;
		font-weight: 300;
	}

	.v-plus-minus-disabled {
		@include btn-text-unselect;
		flex: 1;
		background-color: #d5d5d5;
		cursor: pointer;
		color: black;
		@include v-flex-center;
		cursor: not-allowed;
	}

	p {
		@include n-m-p;
		font-size: 18px;
		font-weight: 300;
	}
}

.prod-fav-button {
	@include btn-general;
	min-width: 50px;
	padding: 0 10px;
	align-items: center;
	justify-content: center;
	border-radius: 0;
	transition: 0.2s;
	border: 0.5px solid whitesmoke;
	background-color: whitesmoke;

	&:hover {
		background-color: #d5d5d5;
		border: 1px solid #d5d5d5;
	}

	.icon {
		height: 25px;
		margin-right: 10px;
	}
}

.out-of-stock-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: crimson;
	background-color: whitesmoke;
	cursor: not-allowed;
	padding: 10px 20px;
	white-space: nowrap;
	margin-right: 10px;
	p {
		@include n-m-p;
	}
}
