@import './checkoutpage';

.cart-page {
	display: flex;
	flex: 1;
	align-items: flex-start;
	margin: 15vh 0%;
	flex-wrap: wrap;
	box-sizing: border-box;
	overflow: auto;
	letter-spacing: 2px;

	@media only screen and (max-width: $large-screen) {
		//flex-direction: column;
		//align-items: center;
	}
}

.shopping-cart {
	box-sizing: border-box;
	min-width: 300px;
	display: flex;
	flex: 6;
	padding: 1% 5%;
	flex-direction: column;
	overflow: hidden;
	@media only screen and(max-width:$medium-screen) {
		padding: 1% 0%;
		h3 {
			margin: 0 4%;
		}
	}
}

.cart-item-vertical {
	display: flex;
	//align-items: center;
	flex-direction: column;
	margin: 10px 0;
	flex: 1;
	background-color: white;
	padding: 20px;
	box-shadow: 0 0 20px rgba($black-color, 0.05);
	border-radius: 4px;
}

.cart-item {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 10px 0;
	flex: 1;
	background-color: white;
	padding: 10px 20px;
	box-shadow: 0 0 20px rgba($black-color, 0.05);
	border-radius: 4px;

	div {
		flex: 1;
		text-align: left;
	}

	p,
	h6 {
		@include n-m-p;
	}

	@media only screen and(max-width:$medium-screen) {
		h6 {
			font-size: 0.9em;
		}
		p {
			font-size: 0.9em;
		}
	}
}

.checkout-form {
	display: flex;
	flex: 3;
	min-width: 300px;
	flex-direction: column;
	margin: 4%;
	padding: 50px;
	border-radius: 4px;
	box-shadow: 0 0 20px rgba($black-color, 0.05);
	background-color: white;
	box-sizing: border-box;

	.checkout-button {
		display: inline-flex;
		padding: 10px 20px;
		margin: 5px 0;
		background-color: rgba(black, 0.9);
		color: white;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: 0.1s;

		&:hover {
			background-color: rgba(black, 0.2);
			color: black;
			border: none;
		}

		p {
			margin: 0;
		}
	}
	@media only screen and (max-width: $medium-screen) {
		margin: 4% 0;
	}
}

.delete-from-cart {
	display: inline-flex;
	//background-color: red;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	color: black;

	&:hover {
		background-color: #e5e5e5;
		color: black;
	}

	p {
		margin: 0;
		font-size: 0.9em;
		font-weight: 400;
	}
}

.cart-qty-button {
	@include btn-general;
	cursor: default;
	height: 30px;
	min-width: 90px;

	.v-plus-minus {
		flex: 1;
		background-color: black;
		cursor: pointer;
		color: white;
		width: 10px;
		@include v-flex-center;
		@include btn-hover;
	}

	p {
		@include n-m-p;
		font-size: 18px;
		font-weight: 300;
	}
}
