//@include

@mixin n-m-p {
	margin: 0;
	padding: 0;
}

@mixin btn-text-unselect {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin btn-general {
	display: inline-flex;
	min-width: 150px;
	height: 50px;
	border: 0.05px solid rgba(black, 0.1);
	margin-right: 10px;
	padding: 0;
	border-radius: 4px;
	cursor: pointer;
	color: default;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	p {
		margin: 0;
		padding: 0;
		color: inherit;
	}
}

@mixin v-flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin btn-hover {
	&:hover {
		background-color: rgba(black, 0.2);
		color: black;
		border: none;

		p {
			color: black;
		}
	}
}

@mixin remove-input-arrow {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}
