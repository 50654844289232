.product-details-reviews-container {
	background-color: white;
	padding: 1em $hp;
	padding-bottom: 10em;
}

.review-star-container {
	display: flex;
	margin: 0.5em 0.5em 0.5em 0;
}

.str-icn {
	color: #f6c340;
	margin-right: 5px;
}

.num-review-text {
	padding: 0;
	margin: 0;
}

.review-section {
	margin: 1em;
}

.review-inputs {
	display: flex;
	flex-direction: column;
}

.review-input-boxes {
	display: flex;
	flex-direction: column;
	margin: 1em 1em 2em 1em;

	@mixin review-inputs {
		width: 60%;
		min-width: 300px;
		margin-bottom: 1em;
		padding: 10px 20px;
		background-color: #f1f1f1;
		border: none;
		&:focus {
			outline: 2px solid black;
		}
	}

	label {
		font-weight: 700;
	}
	textarea {
		@include review-inputs();
	}
	select {
		@include review-inputs();
		height: 50px;
	}
}

.review-item-container {
	padding: 2em;
	flex: 0;
	background-color: #f5f5f5;
	border-radius: 4px;
	margin: 1em 0;
	width: 60%;
	min-width: 280px;

	p {
		@include n-m-p;
	}
	h6 {
		@include n-m-p;
		margin: 0 0.5em;
	}
	.comment {
		margin: 1em 0;
		font-size: 0.9em;
	}
	@media only screen and (max-width: $large-screen) {
		width: 100%;
		margin: 1em 0;

		.comment {
			font-size: 0.8em;
		}
	}
}

.already-reviewed {
	background-color: rgba(crimson, 0.05);
	width: 60%;
	padding: 2em;
	border-radius: 4px;
	p {
		@include n-m-p;
		color: crimson;
		font-weight: 700;
	}
}

.review-posted {
	background-color: rgba(seagreen, 0.05);
	width: 60%;
	padding: 2em;
	border-radius: 4px;
	p {
		@include n-m-p;
		color: seagreen;
		font-weight: 700;
	}
}

.no-reviews-yet {
	background-color: whitesmoke;
	width: 60%;
	padding: 2em;
	border-radius: 4px;
	margin: 1em 0;
	p {
		@include n-m-p;
		color: rgba(black, 0.7);
		font-weight: 700;
	}
}
