.consult {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 80vh;
	padding: 0 $padding-horizontal;
	font-size: 1.8em;
	font-weight: 300;
	text-align: left;
	text-align: center;
	background-color: white;

	h6 {
		font-size: 1em;
		font-weight: 400;
	}

	p {
		font-size: 1em;
		font-weight: 200;
		margin: 0.1em 0;
	}

	@media only screen and(max-width: $medium-screen) {
		font-size: 1.3em;
		min-height: 50vh;
	}
}

.custom-button {
	display: inline-flex;
	background-color: $color-black;
	transition: 0.2s;
	cursor: pointer;
	margin: 1em 0;
	border: 2px solid black;
	color: whitesmoke;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: white;
		color: black;
	}
	p {
		padding: 0;
		margin: 0;
		font-size: 16px;
		padding: 1em 2em;
		color: $color-light;
		font-weight: 500;
		color: inherit;
	}
}
